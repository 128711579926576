import * as React from 'react'
import { observer } from 'mobx-react-lite';
import { withRouterApp } from '@app/util/router.tsx';
import { RouteComponentProps, Link, Redirect } from 'react-router-dom'; 
import { rootContext } from '@app/index';
import * as styles from './proposal.module.sass';
import Button from '@components/common/Button';
import { ValueCard } from '@components/dashboard/Proposal/Analytics/Card';
import Image from '@assets/dashboard/blur_dash.jpg';
import Icon from '@app/components/common/Icon';
import { TEMPLATE_URL } from '@app/util/apiRoot';
import CRM from './CRM';
import Loading from '@app/components/common/Loading';

interface IProposalProps {}

let Proposal: React.FunctionComponent<IProposalProps & RouteComponentProps<{uuid: string}>> = (props) => {
  const rootStore = React.useContext(rootContext);
  let id = props.match.params.uuid;

  React.useEffect(() => {
    rootStore.proposalStore.pullProposal(id)
  }, [id])

  let proposal = rootStore.proposalStore.proposals.find(item => id === item.id);

  React.useEffect(() => {
    proposal = rootStore.proposalStore.proposals.find(item => id === item.id);
  }, [rootStore.proposalStore.proposals.length])

  if (!proposal) {
    return <Loading fullScreen loadingID={id} />
  }

  let proposalLink = `${rootStore.user.organization || 'organization'}.${TEMPLATE_URL}/${proposal.slug}`

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <h1 className={styles.headline}>{proposal.name}</h1>
        </div>
        <div className={styles.links}>
          {proposal.version_active &&
            <a href={`https://${proposalLink}`} target='_blank' className={styles.publishedLink}>
              <p><span>https://</span>{proposalLink}</p>
              <Icon type='link' inheritStyle />
            </a>
          }
          <Link to={`/process/builder/${proposal.id}`}>
            <Button onClick={() => {}} rotateIcon={0} text='Edit Proposal' type='primary' icon='pen-solid' />
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.cards}>
          <ValueCard
            value={proposal.stats.leads_count}
            text='Incoming leads'
            headline='Number of incoming leads'
            icon='crown'
            highlight
          />
          <ValueCard
            value={(Math.round((proposal.stats.time_spent / 60) * 100) / 100)}
            text='minutes'
            headline='Average time viewing your deck'
            icon='eye'
          />
          <ValueCard
            value={proposal.stats.total_views}
            text='Views'
            headline='Total number of views'
            icon='eye'
          />
        </div>
      </div>
      <div className={styles.crm}>
        <CRM proposal={proposal} />
      </div>
    </div>
  )
}

export default withRouterApp<IProposalProps>(observer(Proposal));