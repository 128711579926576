import Proposal from "@app/models/builder/proposal";
import { observable, action, reaction, when } from "mobx";
import ProposalStore from "./ProposalStore";
import UiStore from "./uiStore";
import API from "@app/api";
import AuthStore from "@stores/authStore";
import { RouterStore } from 'mobx-react-router';
import UserStore from "./userStore";
import FormStore from "./formStore";
import Forms from "@stores/forms";
import { trackPage } from "@app/util/analytics";

export default class RootStore {
  @observable
  token = window.localStorage.getItem('jwt');

  @observable
  pendingRequests = 0;

  @observable
  appLoaded = false

  @observable
  servicebotLoaded = false;

  proposalStore: ProposalStore;
  ui: UiStore;
  api: API;
  auth: AuthStore;
  router: RouterStore;
  user: UserStore;
  formStore: FormStore;

  forms: Forms;
  constructor() {
    this.router = new RouterStore();
    this.api = new API(this);
    this.ui = new UiStore(this);

    this.user = new UserStore(this)
    this.auth = new AuthStore(this);
    this.proposalStore = new ProposalStore(this);
    this.formStore = new FormStore(this);

    // forms
    this.forms = new Forms(this);

    reaction(
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem('jwt', token)
        } else {
          window.localStorage.removeItem('jwt')
        }
      }
    )

    reaction(
      () => this.router.location && this.router.location.pathname,
      (path) => {
        trackPage();
      }
    )

    when(
      () => this.appLoaded && this.pendingRequests === 0,
      () => this.stopLoadingAnimation()
    )

    when(
      () => !!this.token,
      () => this.user.getUser()
    )

    reaction(
      () => this.ui.builder.customizationOpen,
      (open) => {
        let baseURL = this.router.location.pathname;
        if (open) {
          this.router.replace(`${baseURL}#cutomization`)
        } else {
          this.router.replace(baseURL)
        }
      }
    )
  }

  @action.bound
  setToken(token: string) {
    this.token = token;
  }

  @action.bound
  removeToken() {
    this.token = null;
  }

  @action.bound
  stopLoadingAnimation() {
    window.LOADING_ANIMATION.destroy();
    document.getElementById('animation')!.style.display = 'none';
  }

  @action.bound
  toggleAppLoaded() {
    this.appLoaded = true;
  }

  @action.bound
  toggleServiceBotLoaded(toggle: boolean) {
    this.servicebotLoaded = toggle;
  }
}