import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { rootContext } from '@app/index';
import * as styles from './modal.module.sass';

interface IModalProps {
  toggleCupon: (val: boolean) => void;
}

const Modal: React.FunctionComponent<IModalProps> = (props) => {
  const [cuponValue, changeCuponValue] = React.useState('');
  const rootStore = React.useContext(rootContext);
  const user = rootStore.user

  return (
    <div>
      <p className={styles.headline}>Enter your coupon code here</p>
      <p style={{ marginBottom: '15px', fontSize: '12px' }} className={styles.text}>Enter your coupon code here. Please note that the discount will be applied but not visible on the pricing at the last step of your checkout process.</p>
      <div>
        <p className={styles.text}>Coupon code</p>
        <input className={styles.input} onChange={(e) => changeCuponValue(e.target.value)} value={cuponValue} type='text'></input>
        {rootStore.user.coupon &&
          <p className={styles.active}>Coupon active: {rootStore.user.coupon.id}</p>
        }
      </div>
      <button className={styles.button} onClick={() => { rootStore.user.applyCupon(cuponValue); props.toggleCupon(false) }}>Apply coupon</button>

    </div>
  );
};

export default observer(Modal);
