import Builder from '@components/builder/Builder';
import Dashboard from '@components/dashboard';
import Eventbrite from '@components/auth/Eventbrite';
import SignIn from '@components/auth/SignIn';
import V0 from '@components/auth/V0';
import Account from '@components/account';
import PasswordReset from '@components/auth/PasswordReset';
import SetPassword from '@components/auth/SetPassword';
import SetEmail from '@components/auth/SetEmail';
import Organization from '@components/auth/Organization';
import Onboarding from "@components/auth/Onboarding";

interface IRoute {
  path: string;
  name: string;
  component: any;
  exact: boolean;
  sidebar: boolean;
  private: boolean;

  redirect?: string;
  wrapProps?: boolean;
}

const routes: IRoute[] = [
  {
    path: '/account',
    name: 'Account',
    component: Account,
    sidebar: true,
    private: true,
    exact: false,
  },
  {
    path: '/process/builder/:uuid',
    name: 'Proposal',
    component: Builder,
    sidebar: false,
    private: true,
    exact: true
  },
  {
    path: '/test-element-proposal',
    name: 'Test Proposal',
    component: Builder,
    sidebar: false,
    private: true,
    exact: true
  },
  {
    path: '/proposals',
    name: 'Proposals',
    component: Dashboard,
    sidebar: true,
    private: true,
    exact: false,
  },
  {
    path: '/signin/eventbrite',
    name: 'Eventbrite signin',
    component: Eventbrite,
    sidebar: false,
    private: false,
    exact: true
  },
  {
    path: '/auth/onboarding',
    name: 'Onboarding',
    component: Onboarding,
    sidebar: false,
    private: true,
    exact: false,
  },
  {
    path: '/auth/signin',
    name: 'Sign In',
    component: SignIn,
    sidebar: false,
    private: false,
    exact: true,
  },
  {
    path: '/auth/signup',
    name: 'Sign Up',
    component: V0,
    sidebar: false,
    private: false,
    exact: true,

  },
  {
    path: '/auth/welcome',
    name: 'Sign Up Welcome',
    component: V0,
    sidebar: false,
    private: false,
    exact: true,

  },
  {
    path: '/auth/reset/password/email',
    name: 'Reset Password email',
    component: PasswordReset,
    sidebar: false,
    private: false,
    exact: true,

  },
  {
    path: '/auth/reset/password/set-new',
    name: 'Set New Password',
    component: SetPassword,
    sidebar: false,
    private: false,
    exact: true,
  },
  {
    path: '/auth/change/email/set-new',
    name: 'Set New Email',
    component: SetEmail,
    sidebar: false,
    private: false,
    exact: true,
  },
  {
    path: '/user/set/organization',
    name: 'Set Organization',
    component: Organization,
    sidebar: false,
    private: true,
    exact: true,
  },
]

export default routes;