import axios, { AxiosInstance } from 'axios';
import RootStore from '@stores/RootStore';
import ProposalAPI from './proposal';
import API_URL from '@util/apiRoot';
import GoogleAPI from './google';

export default class API {

  axios: AxiosInstance;
  root: RootStore;

  proposal: ProposalAPI;
  google: GoogleAPI;

  constructor(root: RootStore) {
    this.root = root;
    this.axios = axios.create({
      baseURL: API_URL
    });

    this.proposal = new ProposalAPI(this, this.axios);
    this.google = new GoogleAPI();

    this.axios.interceptors.request.use(
      config => {
        if (!config.headers.Authorization) {
          const token = this.root.token;

          if (token) {
            config.headers.Authorization = `Token ${token}`;
            config.params = {
              token: token
            }
          }
        }

        return config;
      },
      error => Promise.reject(error)
    );

    this.axios.interceptors.response.use(response => response, error => {
      console.log(error)
      if (error.response.status === 401) {
        this.root.user.forgetUser();
      }

      return Promise.reject(error);
    })

    // Add a request interceptor counter
    this.axios.interceptors.request.use((config) => {
      this.root.pendingRequests++;
      return config;
    }, function (error) {
      return Promise.reject(error);
    });

    // Add a response interceptor counter
    this.axios.interceptors.response.use((response) => {
      this.root.pendingRequests--;
      return response;
    }, (error) => {
      this.root.pendingRequests--;
      return Promise.reject(error);
    });
  }

  login(values: { email: string, password: string}) {
    return this.axios.post('/sessions', {user: values}).then((res) => res.data);
  }

  register(values: { email: string, password: string, organization: string }) {
    return this.axios.post('/users', {user: values}).then((res) => res.data);
  }

  userInfo() {
    return this.axios.get('/users/me').then((res) => res.data);
  }

  addExtraInfo(values: any) {
    return this.axios.post('/users/extra_info', values).then(res => res.data);
  }

  cancelSubscribtion() {
    return this.axios.post('/users/cancel_subscription').then(res => res.data);
  }

  changePlan(plan_id: string) {
    return this.axios.post('/users/change_plan', { plan_id }).then(res => res.data);
  }

  updateUser(user: any) {
    return this.axios.patch('/users/me', { ...user }).then(res => res.data);
  }

  userForms() {
    return this.axios.get('/forms').then((res) => res.data);
  }

  formCategories() {
    return this.axios.get('/categories').then((res) => res.data);
  }

  getForm(id: string) {
    return this.axios.get(`/forms/${id}`).then((res) => res.data);
  }

  getFormCategory(id: string) {
    return this.axios.get(`/categories/${id}`).then((res) => res.data);
  }

  getFormStructure(id: string) {
    return this.axios.get(`/form_process/${id}/`).then(res => res.data);
  }

  getProcesses() {
    return this.axios.get('/form_processes').then((res) => res.data);
  }

  getProcess(id: string) {
    return this.axios.get(`/form_processes/${id}/`).then((res) => res.data);
  }

  duplicateProcess(id: string) {
    return this.axios.post(`/form_processes/${id}/duplicate`).then(res => res.data);
  }

  deleteProposal(id: string) {
    return this.axios.delete(`/form_processes/${id}`).then(res => res.data);
  }

  deleteUser() {
    return this.axios.delete('/users/me').then(res => res.data);
  }

  updateCreditCard(token: any) {
    return this.axios.post('/users/change_credit_card', { stripe: { ...token } }).then(res => res.data);
  }

  async createProcess(name: string, form_id: string) {
    let templates = await this.axios.get('/templates').then(res => res.data);
    return this.axios.post('/form_processes', { form_process: { name, form_id, template_id: templates[0].id, position: 0 }}).then((res) => res.data);
  }

  async createProcessFromExample(proposal_name: string, example_id: string) {
    return this.axios.post('/form_processes/create_from_example', { example_id, proposal_name }).then(res => res.data);
  }

  fetchExamples() {
    return this.axios.get('form_processes/examples').then(res => res.data);
  }

  updateElement(id: string, data: any) {
    return this.axios.patch(`/element_answers/${id}`, { element_answer: {data: data}}).then((res) => res.data);
  }

  updateProposalSettings(id: string, settings: any) {
    return this.axios.patch(`/form_processes/${id}`, { form_process: { settings: settings }}).then(res => res.data);
  }

  updateTemplateCustomization(customization: any, id: string) {
    return this.axios.patch(`/customizations/${id}`, { customization }).then((res) => res.data)
  }

  updateSectionSettings(id: string, settings: any) {
    return this.axios.patch(`/sections/${id}/`, { section: { settings } }).then(res => res.data);
  }

  toogleSectionVisibility(toggle: boolean, id: string) {
    return this.axios.post(`/sections/${id}/${toggle ? 'visible' : 'not_visible'}`)
  }

  publishProposalVersion(versionId: string, proposalId: string) {
    return this.axios.post(`/form_processes/${proposalId}/publish?version_id=${versionId}`).then(res => res.data);
  }

  generatePdf(proposalId: string) {
    return this.axios.get(`/form_processes/${proposalId}/pdf`, {responseType: 'blob'}).then(res => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
    });
  }

  renameProposal(id: string, name: string) {
    return this.axios.patch(`/form_processes/${id}`, { form_process: { name } }).then(res => res.data);
  }

  resetPasswordEmail(email: string) {
    return this.axios.post('/users/reset_password ', { user: { email }}).then(res => res.data)
  }

  setNewPassword(token: string, password: string) {
    return this.axios.post('/users/reset_password ', { user: { token, password, password_confirmation: password }}).then(res => res.data);
  }

  resetEmail(email: string) {
    return this.axios.post('/users/change_email', { user: { email } }).then(res => res.data)
  }

  setNewEmail(token: string, email: string) {
    return this.axios.post('/users/change_email', { user: { token, email } }).then(res => res.data);
  }

  togglePageHide(hidden: boolean, id: string) {
    return this.axios.patch(`/pages/${id}`, { page: { hidden } })
  }

  getFolders() {
    return this.axios.get('/folders').then(res => res.data);
  }

  puchProposalFolderChanges(proposal_id: string, folder_id: string | null) {
    return this.axios.patch(`/form_processes/${proposal_id}`, { form_process: { folder_id: folder_id} }).then(res => res.data);
  }

  updateFolderName(id: string, name: string) {
    return this.axios.patch(`/folders/${id}`, { name }).then(res => res.data);
  }

  createFolder(name: string) {
    return this.axios.post('/folders', { name }).then(res => res.data)
  }

  deleteFolder(id: string) {
    return this.axios.delete(`/folders/${id}`).then(res => res.data);
  }

  applyCupon(id: string) {
    return this.axios.post('/coupons', { id }).then(res => res.data);
  }
}

