export interface IPremiumLimitation {
  maxProposals: number;
  maxKeyNumbers: number;
  maxAudienceGroups: number;
  maxPackageTiers: number;
  maxPackageBenefits: number;
  maxParagraphs: number;
  pdfExport: boolean;
}

let plans = {
  'Premium': {
    id: 'prod_FXIGw5MYfXz5N0',
    state: 'normal',
    limitation: {
      maxProposals: 3,
      maxKeyNumbers: 999,
      maxAudienceGroups: 999,
      maxPackageTiers: 999,
      maxPackageBenefits: 999,
      maxParagraphs: 999,
      pdfExport: false
    },
    items: [
      {
        text: '3 Active Proposals',
        active: true,
        tooltip: 'Create & manage up to 3 proposals from your account.'
      },
      {
        text: 'Premium CRM',
        active: true,
        tooltip: 'Receive & manage unlimited incoming leads from your dashboard. Add states and notes and improve your sales process.'
      },
      {
        text: 'Premium Proposal Sections',
        active: true,
        tooltip: 'Improve your deck with Premium section such as Speakers, Team, Marketing or Testimonial. Sections will be added on regular basis.'
      },
      {
        text: 'Premium Support',
        active: true,
        tooltip: 'Get an answer to your question within 24 hours.'
      },
      {
        text: 'Premium Proposal Customization',
        active: true,
        tooltip: 'Upload your logo, change the colors set, reorder section, add transition pictures or videos. In other words: Make it truly yours!'
      },
      {
        text: 'Premium Notifications & Metrics',
        active: true,
        tooltip: 'Get Real-time tracking of your proposal opens and views. Get weekly/ monthly reports.'
      },
      {
        text: 'Rename & Reorder Pages',
        active: true,
        tooltip: 'Change the default sections names for anything you wish and reorder them.'
      },
      {
        text: 'Remove Sponseasy Watermark',
        active: false,
      },
      {
        text: 'Customization with HTML/ CSS',
        active: false
      },
      {
        text: 'Live Collaboration',
        active: false,
      },
      {
        text: 'PDF Export',
        active: false,
      },
      {
        text: 'Custom Proposal URL',
        active: false,
      },
    ],
    headline: 'Premium',
    description: 'Organizing a bigger project and want full control over your proposal design? This plan is for you.',
    plans: {
      'month': { id: 'plan_FcBJjhfRCNkFdF', price: 39},
      'year': { id: 'plan_FcBJ2qxbXap3L9', price: 348}
    }
  },
  'Enterprise': {
    id: 'prod_FcBMcU3wtKpOxl',
    headline: 'Professional',
    description: 'Taking sponsorship really seriously and looking for flexibility + scalability? This plan is a no brainer!',
    limitation: {
      maxProposals: 999,
      maxKeyNumbers: 999,
      maxAudienceGroups: 999,
      maxPackageTiers: 999,
      maxPackageBenefits: 999,
      maxParagraphs: 999,
      pdfExport: true
    },
    items: [
      {
        text: 'Unlimited active Proposals',
        active: true,
        tooltip: 'Create & manage unlimited amount of proposals'
      },
      {
        text: 'Premium CRM',
        active: true,
        tooltip: 'Receive & manage unlimited incoming leads from your dashboard. Add states and notes and improve your sales process.'
      },
      {
        text: 'Premium Proposal Sections',
        active: true,
        tooltip: 'Improve your deck with Premium section such as Speakers, Team, Marketing or Testimonial. Sections will be added on regular basis.'
      },
      {
        text: 'Premium Support',
        active: true,
        tooltip: 'Get an answer to your question within 24 hours.'
      },
      {
        text: 'Premium Proposal Customization',
        active: true,
        tooltip: 'Upload your logo, change the colors set, reorder section, add transition pictures or videos. In other words: Make it truly yours!'
      },
      {
        text: 'Premium Notifications & Metrics',
        active: true,
        tooltip: 'Get Real-time tracking of your proposal opens and views. Get weekly/ monthly reports.'
      },
      {
        text: 'Rename & Reorder Pages',
        active: true,
        tooltip: 'Change the default sections names for anything you wish and reorder them.'
      },
      {
        text: 'Remove Sponseasy Watermark',
        active: true,
      },
      {
        text: 'Customization with HTML/ CSS',
        active: true
      },
      {
        text: 'PDF Export',
        active: true,
      },
      {
        text: 'Live Collaboration',
        active: true,
        soon: true
      },
      {
        text: 'Custom Proposal URL',
        active: true,
        soon: true
      },
    ],
    state: 'favorite',
    plans: {
      'month': { id: 'plan_FcBMzb8ks3PHzK', price: 129},
      'year': { id: 'plan_FcBNIaFfFYoekH', price: 1188}
    }
  }
}

if (process.env.TARGET === 'production') {
  Object.assign(plans["Premium"], {
    id: 'prod_FcBPpIMbmv2M18',
    plans: {
      'month': { id: 'plan_FcBP7PAQgvpvMm', price: 39 },
      'year': { id: 'plan_FcBPH21RHkcRnx', price: 348 }
    }
  })

  Object.assign(plans['Enterprise'], {
    id: 'prod_FcBPYWmVF9uClG',
    plans: {
      'month': { id: 'plan_FcBPkLPSKC1nf7', price: 129 },
      'year': { id: 'plan_FcBPBHXk98gfry', price: 1188 }
    }
  })
}

export default plans;