import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './switcher.module.sass';
import cn from 'classnames';

interface ISwitcherProps {
  active?: boolean;
  onChange: (active: boolean) => void;
}

let Switcher: React.FunctionComponent<ISwitcherProps> = (props) => {
  return (
    <div onClick={() => props.onChange(!props.active)} className={styles.switcher}>
      <div className={cn(styles.ball, { [styles.active]: props.active })}></div>
    </div>
  );
}

export default observer(Switcher);