import * as React from 'react'
import { observer } from 'mobx-react-lite';
import * as styles from './pricing.module.sass';
import Tier, { TierFree, TierBiz } from './Tier';
import Switcher from './Switcher';
import cn from 'classnames';
import { rootContext } from '@app/index';
import premium from '@util/premium';
import { withRouterApp } from '@app/util/router';
import { RouteComponentProps } from 'react-router';
import QS from 'query-string';
import STRIPE_KEY from '@app/util/stripe';
import Modal from '@components/common/Modal';
import ModalContent from './Modal';

interface IPricingProps {}

let Pricing: React.FunctionComponent<IPricingProps & RouteComponentProps<{planId?: string}>> = (props) => {
  const [yearlyActive, toggleYearlyActive] = React.useState(true);
  const [cuponModalOpen, toggleCupon] = React.useState(false);

  const stripe = window.Stripe(STRIPE_KEY);
  const rootStore = React.useContext(rootContext);
  let user = rootStore.user;

  React.useEffect(() => {
    let qs = QS.parse(props.location.search);

    if (qs.planId) {
      goToCheckout(qs.planId! as string)
    }
  }, [props.location.search])

  function goToCheckout(planId: string) {
    stripe.redirectToCheckout({
      items: [{ plan: planId, quantity: 1 }],
      successUrl: `${window.location.origin}/account/premium/complete`,
      cancelUrl: `${window.location.origin}/account/premium/error`,
      customerEmail: rootStore.user.email,
      clientReferenceId: rootStore.user.id
    })
      .then(function (result: any) {
        if (result.error) {
          console.error(result.error)
        }
      });
  }

  function click(plan: string) {
    goToCheckout(plan);
  }

  let tiers = Object.entries(premium);

  let tiersJSX = tiers.map(item => {
    let name = item[0];
    let tier = item[1];

    return <Tier
      key={tier.id}
      priceMo={tier.plans.month.price}
      priceYear={tier.plans.year.price}
      billingRange={yearlyActive ? 'year' : 'month'}
      headline={tier.headline}
      description={tier.description}
      onClick={click}
      productId={tier.id}
      planIdMonth={tier.plans.month.id}
      planIdYear={tier.plans.year.id}
      currentProductId={user.premiumProductId}
      items={tier.items}
      state={tier.state as any}
    />
  })

  tiersJSX.push(<TierBiz
    key='biz'
    state='normal'
    headline='Business'
    description='Owning organization with event franchise concept? Want to unite all of the sponsors under one roof? Contact us.'
    secondDescription='Get all from previous plan togather with features for organizations in event franchise concept'
    items={[
      {
        text: 'Custom deck template',
        active: true,
        tooltip: 'Custom deck template'
      },
    ]}
  />)

  return (
    <div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h1 className={styles.headline}>Start your 14 day trial by picking one of the plans 🚀</h1>
          <div style={{ position: 'relative' }}>
            <p onClick={() => toggleCupon(true)} className={styles.cuponCode}>Have coupon code?</p>
            <div className={styles.pricingSwitch}>
              <p onClick={() => toggleYearlyActive(false)} className={cn(styles.pricingDescription, { [styles.activeRange]: !yearlyActive })}>Monthly billing</p>
              <Switcher active={yearlyActive} onChange={toggleYearlyActive} />
              <p onClick={() => toggleYearlyActive(true)} className={cn(styles.pricingBillingTime, { [styles.activeRange]: yearlyActive })}>Yearly billing  - <span>save 30%</span></p>
            </div>
          </div>
        </div>
        <div className={styles.tiers}>
          {tiersJSX}
        </div>
        <div style={{ display: 'none'}} className={styles.socialProof}>
          <div>
            <img width='400' src="https://cdn.stockphotosecrets.com/wp-content/uploads/2018/08/hide-the-pain-stockphoto-840x560.jpg" />
          </div>
          <div className={styles.socialText}>
            <p>“Sponseasy allowed me to stop spending hours in PowerPoint creating not professional looking proposal. Now I can really focus on spedning more time on communicating with Sponsor and less on creating beautiful proposals.”</p>
            <div className={styles.socialPerson}>
              <p>Joana Parker, <span>CEO at SanFranEvents</span></p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.faq}>
        <p>This may be on your mind</p>
        <div className={styles.faqs}>
          <div className={styles.faqItem}>
            <div className={styles.faqItemContent}>
              <p>What is included in the free trial?</p>
              <p>During the free trial we give you access to all the premium features during 14 days. After this timing, your proposals will still be online but the premium sections will be removed.</p>
            </div>
          </div>
          <div className={styles.faqItem}>
            <div className={styles.faqItemContent}>
              <p>Can I change my plan later?</p>
              <p>At any time, you can upgrade or downgrade your plan, no question asked.</p>
            </div>
          </div>
          <div className={styles.faqItem}>
            <div className={styles.faqItemContent}>
              <p>How does annual pricing work?</p>
              <p>It's very simple: we bill you once for a full 12 months of access to Sponseasy — at a discount of up to 30% on the per-month price.</p>
            </div>
          </div>
          <div className={styles.faqItem}>
            <div className={styles.faqItemContent}>
              <p>I'm not a designer or developer. Will it work for me?</p>
              <p>We built Sponseasy with you in mind. If you don't have technical or graphical skills, it will still be dead simple for you to create a stunning professional deck. And if you have this skills, SKY IS THE LIMIT 🚀!</p>
            </div>
          </div>
        </div>
      </div>
      <Modal clean padding={30} maxWidth={352} onRequestClose={() => toggleCupon(false)} isOpen={cuponModalOpen}>
        <ModalContent toggleCupon={toggleCupon} />
      </Modal>
    </div>
  );
}

export default  withRouterApp(observer(Pricing));